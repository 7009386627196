import { toJS } from 'mobx';
import { cast, castToSnapshot, flow, Instance, types } from 'mobx-state-tree';

import {
  GENDERS,
  OnBoardingResultItem,
  StepsProps,
  StepsTypes,
} from 'types/types';

import { SensesSDK } from 'services/api/senses';
import { FirebaseService } from 'services/remoteConfig';

import { imageLoader } from 'utils/common';
import { convertObjWithIdRecursive } from 'utils/object/convertObjWithId';

import defaultOnBoarding from 'constants/defaultOnBoarding.json';

const Answer = types.model('Question', {
  text: types.string,
  id: types.identifierNumber,
});

const Question = types.model('Question', {
  text: types.string,
  shown: false,
  percent: types.number,
  answers: types.array(Answer),
});

const LoaderSlide = types.model('LoaderSlide', {
  text: types.string,
  percent: types.number,
  title: types.string,
});

const Variant = types.model('Variant', {
  id: types.identifier,
  text: types.string,
  emoji: '',
  img: '',
});

export interface IVariant extends Instance<typeof Variant> {}

const Review = types.model('Review', {
  text: types.string,
  author: types.string,
  age: types.number,
  icon: types.string,
});

const Expert = types.model('Expert', {
  imageSrc: types.string,
  name: types.string,
  occupation: types.string,
  description: types.string,
});

const LoadingScreen = types.model('LoadingScreen', {
  imageSrc: types.string,
  time: types.number,
  badgeText: types.optional(types.string, ''),
  footerText: types.optional(types.string, ''),
});

export type TLoadingScreen = Instance<typeof LoadingScreen>;

export interface IExpert extends Instance<typeof Expert> {}

export interface IReview extends Instance<typeof Review> {}

const INITIAL_STEP_NUMBER = 1;

const WithBackgroundUrl = types.model('WithBackgroundUrl', {
  backgroundUrl: types.optional(types.string, ''),
  avatar: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  text: types.optional(types.string, ''),
});

const Step = types
  .model('Step', {
    id: types.identifier,
    title: types.optional(types.string, ''),
    subtitle: types.optional(types.string, ''),
    type: types.enumeration<StepsTypes>(Object.values(StepsTypes)),
    text: types.optional(types.string, ''),
    annotation: types.optional(types.string, ''),
    imageUrl: types.optional(types.string, ''),
    html: types.optional(types.string, ''),
    backgroundColor: types.optional(types.string, ''),
    placeholder: types.optional(types.string, ''),
    value: types.optional(types.string, ''),
    isGoalQuestion: types.optional(types.boolean, false),
    removeSpacer: types.optional(types.boolean, false),
    buttonText: types.optional(types.string, ''),
    variants: types.optional(types.array(Variant), []),
    slides: types.optional(types.array(LoaderSlide), []),
    questions: types.optional(types.array(Question), []),
    reviews: types.optional(types.array(Review), []),
    chosenVariantsIds: types.optional(types.array(types.string), []),
    experts: types.optional(types.array(Expert), []),
    answers: types.optional(
      types.array(types.union(types.number, types.string)),
      [],
    ),
    showProgress: types.optional(types.boolean, true),
    showCount: types.optional(types.boolean, true),
    backIconColor: types.optional(types.string, 'white'),
    chosenRate: types.optional(types.number, -1),
    minValue: types.optional(types.number, 0),
    maxValue: types.optional(types.number, 100),
    defaultValue: types.optional(types.number, 18),
    genderOptional: types.optional(types.string, ''),
    cards: types.optional(types.array(WithBackgroundUrl), []),
    headerText: types.optional(types.string, ''),
    leftLabel: types.optional(types.string, 'Low'),
    rightLabel: types.optional(types.string, 'High'),
    ratingOptions: types.optional(types.array(types.string), [
      '1',
      '2',
      '3',
      '4',
      '5',
    ]),
    loadingScreens: types.optional(types.array(LoadingScreen), []),
  })
  .views(self => ({
    get isAnswerEmpty() {
      return !self.chosenVariantsIds.length;
    },
    get result(): { questionId: string; title: string; result: string[] } {
      return {
        questionId: self.id,
        title: self.title,
        result: toJS(self.chosenVariantsIds),
      };
    },
  }))
  .actions(self => ({
    selectVariant(id: string) {
      const variant = self.variants.find(q => q.id === id);
      const answer = variant!.text;

      if (
        self.type === StepsTypes.singleChoice ||
        self.type === StepsTypes.agePiker
      ) {
        self.chosenVariantsIds = cast([id]);
        self.answers = cast([answer]);
        return;
      }

      if (self.chosenVariantsIds.includes(id)) {
        self.chosenVariantsIds = cast(
          self.chosenVariantsIds.filter(item => item !== id),
        );
        self.answers = cast(self.answers.filter(item => item !== answer));
        return;
      }
      self.chosenVariantsIds.push(id);
      self.answers.push(answer);
    },
    applyRate(rate: number) {
      self.chosenRate = rate;
    },
  }));

export interface IOnboardingStep extends Instance<typeof Step> {}

const Rating = types.model('Rating', {
  stepNumber: types.number,
  value: types.string,
});

export const OnBoardingStore = types
  .model('OnBoardingStore', {
    id: '',
    steps: types.optional(types.array(Step), castToSnapshot(defaultOnBoarding)),
    isLoading: false,
    loaderFinished: false,
    isQuestionPopupFinished: false,
    isOnBoardingPassed: types.maybeNull(types.boolean),
    currentGender: types.optional(
      types.enumeration('currentGender', Object.values(GENDERS)),
      GENDERS.ALL,
    ),
    ratingQuestionValue: types.optional(types.array(Rating), []),
    watercups: types.optional(types.number, 0),
    goalAnswer: types.optional(types.string, ''),
    currentStepNumber: types.optional(types.number, INITIAL_STEP_NUMBER),
    imagesList: types.optional(types.array(types.string), []),
  })
  .views(self => ({
    get filteredSteps() {
      return self.steps.filter(step => {
        if (step.genderOptional === '' || self.currentGender === GENDERS.ALL) {
          return true;
        }
        return step.genderOptional === self.currentGender;
      });
    },
  }))
  .views(self => ({
    get canGoBack() {
      return self.currentStepNumber > 1;
    },

    get firstStep() {
      if (self.filteredSteps.length) {
        const [firstStep] = self.filteredSteps;
        return firstStep;
      }
    },

    get stepsCount() {
      return self.steps.length;
    },

    get stepsCountForMale() {
      return self.filteredSteps.length;
    },

    get nextStep() {
      if (self.filteredSteps.length > self.currentStepNumber) {
        return self.filteredSteps[self.currentStepNumber - 1];
      }
    },

    get isLastStep() {
      return self.filteredSteps.length === self.currentStepNumber;
    },

    get progress() {
      return Math.floor(
        (self.currentStepNumber / self.filteredSteps.length) * 100,
      );
    },

    get isFirstStep() {
      return self.currentStepNumber === INITIAL_STEP_NUMBER;
    },

    get results(): OnBoardingResultItem[] {
      return self.filteredSteps.map(item => item.result).filter(item => item);
    },

    get currentStep() {
      return (
        self.filteredSteps[self.currentStepNumber - 1] ||
        self.filteredSteps[INITIAL_STEP_NUMBER]
      );
    },
  }))
  .views(self => ({
    get shouldRenderButton(): boolean {
      switch (self.currentStep.type) {
        case StepsTypes.ratingQuestion:
          return (
            self.ratingQuestionValue.findIndex(
              rate => rate.stepNumber === self.currentStepNumber,
            ) !== -1
          );
        case StepsTypes.loader:
          return self.loaderFinished;
        case StepsTypes.questionPopup:
          return self.isQuestionPopupFinished;
        case StepsTypes.agePiker:
        case StepsTypes.singleChoice:
        case StepsTypes.email:
        case StepsTypes.info:
        case StepsTypes.rate:
        case StepsTypes.prePaywall:
        case StepsTypes.loadingScreens:
        case StepsTypes.imageDescription:
          return false;
        default:
          return true;
      }
    },
  }))
  .actions(self => ({
    preloadImages: flow(function* () {
      for (const src of self.imagesList) {
        yield imageLoader(src);
      }
    }),
  }))
  .actions(self => ({
    applyGenderType: (genderType: GENDERS) => {
      self.currentGender = genderType;
    },

    selectWaterCups: (newWaterCups: number) => {
      self.watercups = newWaterCups;
    },

    setGoalAnswer: (answer: string) => {
      self.goalAnswer = answer;
    },

    fetchSteps: flow(function* () {
      self.isLoading = true;
      try {
        const { _id, steps } = yield SensesSDK.getOnboarding(
          FirebaseService.onboardingType || '',
        );
        self.steps = convertObjWithIdRecursive(steps);
        self.currentGender = GENDERS.ALL;
        self.id = _id;
        self.isOnBoardingPassed = false;

        self.imagesList = steps
          .filter((item: StepsProps) => !!item.imageUrl)
          .map((item: StepsProps) => item.imageUrl);

        self.preloadImages();
      } catch (error) {
        console.log(error);
      } finally {
        self.isLoading = false;
      }
    }),

    onNextStep() {
      self.currentStepNumber += 1;
    },

    onPreviousStep() {
      self.currentStepNumber -= 1;
    },

    finishOnBoarding: flow(function* () {
      try {
        self.isLoading = true;
        const result = self.results;
        const body = {
          onBoardingId: self.id,
          result,
        };
        SensesSDK.postOnboardingResult(body);
      } catch (error) {
        console.log(error);
      } finally {
        self.isOnBoardingPassed = true;
        self.isLoading = false;
      }
    }),

    setOnboardingProgress(value: number) {
      self.currentStepNumber = value;
    },

    setRatingQuestionValue(value: string, stepNumber: number) {
      const elementIndex = self.ratingQuestionValue.findIndex(
        rate => rate.stepNumber === stepNumber,
      );
      if (elementIndex !== -1) {
        self.ratingQuestionValue[elementIndex] = { value, stepNumber };
      } else {
        self.ratingQuestionValue.push({ value, stepNumber });
      }
    },

    finishLoader() {
      self.loaderFinished = true;
    },

    questionPopupFinish() {
      self.isQuestionPopupFinished = true;
    },

    setQuestionShown(index: number) {
      self.currentStep.questions[index].shown = true;
    },
  }));
